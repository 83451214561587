import { Component, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { AlertController, IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { STORAGE_KEY_ID, STORAGE_KEY_PUSH } from './shared/common/const';
import { PushNotificationComponent } from './shared/component/push-notification/push-notification.component';
import { UserService } from './shared/service/api/user.service';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private userService: UserService,
    private routerOutlet: IonRouterOutlet,
    public modalController: ModalController,
    private alertController: AlertController
  ) {
    this.platform.backButton.subscribeWithPriority(-1, () => App.exitApp());
  }

  ngOnInit() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          if (!localStorage.getItem(STORAGE_KEY_PUSH)) {
            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration',
              async (token: Token) => {
                localStorage.setItem(STORAGE_KEY_PUSH, token.value);
                const uuid = (await Device.getId()).uuid;
                this.userService.registerDevice(
                  localStorage.getItem(STORAGE_KEY_ID), this.platform.is('android') ? 2 : 1, uuid, token.value
                ).subscribe();
              }
            );
          }

          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError', (error: Error) => console.dir(error));

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
              const modal = await this.modalController.create({
                component: PushNotificationComponent,
                componentProps: {
                  title: notification.title,
                  body: notification.body,
                  link: notification.data.link
                }
              });
              return await modal.present();
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => console.dir(notification));
        }
      });
    }
  }

  exitApp() {
    if (!this.routerOutlet.canGoBack()) {
      this.alertController.create({
        header: 'Thoát!!!',
        message: 'Bạn muốn thoát ứng dụng?',
        buttons: [
          {
            text: 'Không',
            role: 'cancel'
          }, {
            text: 'Đồng ý',
            handler: () => {
              App.exitApp();
            }
          }
        ]
      }).then((alert) => alert.present());
    }
  }
}
