import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  @Input() title = '';
  @Input() body = '';
  @Input() link;
  constructor(private modalController: ModalController, private router: Router) { }

  ngOnInit() {
  }

  closePopup(url: string) {
    this.modalController.dismiss();
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
