import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Respond, User } from '../../common/model/model';

const userPath = 'User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userNameSource = new BehaviorSubject('');
  currentUserName = this.userNameSource.asObservable();

  constructor(private http: HttpClient) { }

  signIn(userName: string, password: string): Observable<Respond> {
    return this.http.post<Respond>(`${environment.url}/SignIn`, { userName, password });
  }

  forgotPassword(userName: string): Observable<Respond> {
    return this.http.post<Respond>(`${environment.url}/ForgotPassword`, JSON.stringify(userName), {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      })
    });
  }

  getUser(): Observable<Respond> {
    return this.http.post<Respond>(`${environment.url}/Profile`, {});
  }

  editUser(user: User): Observable<Respond> {
    return this.http.post<Respond>(`${environment.url}/Profile/Update`, { ...user });
  }

  changeUserName(userName: string) {
    this.userNameSource.next(userName);
  }

  registerDevice(userId: string, deviceType: number, identifier: string, pushId: string) {
    return this.http.post<Respond>(`${environment.url.replace('/Mobile', '')}/userDevices/registerDevice`, {
      userId, deviceType, identifier, pushId,
    });
  }
}
