import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { STORAGE_KEY_TOKEN } from '../const';

@Injectable({
  providedIn: 'root'
})
export class CheckAuth implements CanLoad {

  constructor(private router: Router) { }

  canLoad() {
    return localStorage.getItem(STORAGE_KEY_TOKEN) ? true : this.router.navigateByUrl('/user/login');
  }
}
