import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { AuthInterceptor } from './shared/common/interceptor/auth-interceptor';
import { ErrorInterceptor } from './shared/common/interceptor/error-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localeVi from '@angular/common/locales/vi';
import { DatePipe, registerLocaleData } from '@angular/common';
registerLocaleData(localeVi);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [
    DatePipe,
    { provide: IonRouterOutlet, useValue: {} },
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
