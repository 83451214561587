/* eslint-disable @typescript-eslint/naming-convention */
export const STORAGE_KEY = 'CD_TAY_DO_APP';
export const STORAGE_KEY_ID = STORAGE_KEY + '_id';
export const STORAGE_KEY_NAME = STORAGE_KEY + '_name';
export const STORAGE_KEY_ROLE = STORAGE_KEY + '_role';
export const STORAGE_KEY_TOKEN = STORAGE_KEY + '_token';
export const STORAGE_KEY_USERNAME = STORAGE_KEY + '_username';
export const STORAGE_KEY_PUSH = STORAGE_KEY + '_push';
export const STORAGE_KEY_ERROR = STORAGE_KEY + '_error';
export const CANCEL_TEXT = 'cancel';

export const minWidth = 576;
export const clientWidth = 1200;
export const sideBarWidthClose = 50;
export const sideBarWidthOpen = 200;
export const defaultPageSize = 20;

export const UN_ATTENDANCE = 99
export const JOINED = 2


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getTypeExam(type) {
  switch (type) {
    case 1:
      return 'Thường xuyên';
    case 2:
      return 'Giữa kỳ';
    case 3:
      return 'Cuối kỳ';
    default:
      return '';
  }
}

export const formRequestType = [
  {type: 1, title: 'Bảng điểm năm học'},
  {type: 2, title: 'Chứng nhận tốt nghiệp tạm thời'},
  {type: 3, title: 'Bảng điểm từ đầu khóa học'},
  {type: 4, title: 'Bảng điểm năm học'},
  {type: 5, title: 'Bảng điểm học kỳ'},
  {type: 6, title: 'Giấy chứng nhận học tại trường'},
]
export const formRequestStatus = [
  {type: 1, title: 'Chưa xác nhận'},
  {type: 2, title: 'Đang xử lý'},
  {type: 3, title: 'Đã xác nhận'},
  {type: 4, title: 'Đã nhận kết quả'}
]
export const downloadFileExport = (res: any, name: string) => {
  if (res.ok && res.body) {
    /* Get filename */
    const disposition = res?.headers.get('Content-Disposition') || res?.headers.get('content-disposition') || '';
    const filename = disposition ?
      decodeURIComponent(disposition.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim()) :
      `${name}.xlsx`;
    /* Get blob */
    const blob = new Blob([res.body], {
      type: 'application/vnd.openxmlformats officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    /* Download */
    const link = document.createElement('a');
    link.setAttribute('download', filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
