import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorComponent } from '../../component/error/error.component';
import { ModalController } from '@ionic/angular';
import {STORAGE_KEY_ERROR} from "../const";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router, public modalController: ModalController
  ) { }

  async showError(error: HttpErrorResponse) {
    const modal = await this.modalController.create({
      component: ErrorComponent,
      componentProps: {title: error.error.errors.exceptionMessage}
    });
    await modal.present();
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body.errors) {
          this.showError({ error: event.body } as HttpErrorResponse);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigateByUrl('/user/login');
          return throwError(() => { });
        } else if (error.status === 400) {
          this.showError(error);
          return throwError(error);
        }else if (error.status === 423) {
          localStorage.setItem(STORAGE_KEY_ERROR, JSON.stringify(error.error.errors))
          this.router.navigateByUrl('/main/enforce-read');
          return throwError(() => { });
        } else {
          this.showError(error);
          return throwError(error);
        }
      })
    );
  }
}
